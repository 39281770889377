<template>
  <div class="flex flex-col gap-8">
    <h2 class="text-center text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
      Dutch bat trapping and telemetry database
    </h2>

    <FormKitSchema
      submit-label="Sign in"
      v-bind="formSchema"
    />

    <div class="flex">
      <NuxtLink
        :to="{name: 'auth-request-password'}"
        class="font-semibold text-indigo-600"
      >
        Forgot your password?
      </NuxtLink>
      <span class="grow" />
      <NuxtLink
        :to="{name: 'auth-register'}"
        class="font-semibold text-indigo-600"
      >
        No account yet?
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  auth: false,
  layout: 'auth',
  title: 'Log in',
});

const formSchema = useSofieLoginSchema();
</script>
